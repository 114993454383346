import Ranebennur1 from "../image/Ranebennur1.png";
import Ranebennur2 from "../image/Ranebennur2.png";
import Ranebennur3 from "../image/Ranebennur3.png";
import Ranebennur4 from "../image/Ranebennur4.png";
import Ranebennur5 from "../image/Ranebennur5.png";
import Ranebennur6 from "../image/Ranebennur6.png";
import Ranebennur7 from "../image/Ranebennur7.png";
import Ranebennur8 from "../image/Ranebennur8.png";
import Ranebennur9 from "../image/Ranebennur9.png";
import Kadiri1 from "../image/Kadiri1.png";
import Kadiri2 from "../image/Kadiri2.png";
import Kadiri3 from "../image/Kadiri3.png";
import Kadiri4 from "../image/Kadiri4.png";
import Kadiri5 from "../image/Kadiri5.png";
import Kadiri6 from "../image/Kadiri6.png";
import Kadiri7 from "../image/Kadiri7.png";
import Kadiri8 from "../image/Kadiri8.png";
import Kadiri9 from "../image/Kadiri9.png";
import Ananthpur1 from "../image/Ananthpur1.png";
import Ananthpur5 from "../image/Ananthpur5.png";
import Ananthpur7 from "../image/Ananthpur7.png";
import Ananthpur8 from "../image/Ananthpur8.png";
import Periyapatna1 from "../image/Periyapatna1.png";
import Periyapatna2 from "../image/Periyapatna2.png";
import Periyapatna3 from "../image/Periyapatna3.png";
import Periyapatna4 from "../image/Periyapatna4.png";
import Periyapatna5 from "../image/Periyapatna5.png";
import Rayachuti1 from "../image/Rayachuti1.png";
import Rayachuti2 from "../image/Rayachuti2.png";
import Rayachuti3 from "../image/Rayachuti3.png";
import Rayachuti4 from "../image/Rayachuti4.png";
import Rayachuti5 from "../image/Rayachuti5.png";
import Rayachuti6 from "../image/Rayachuti6.png";
import Rayachuti7 from "../image/Rayachuti7.png";
import Rayachuti8 from "../image/Rayachuti8.png";
import Repalli1 from "../image/Repalli1.png";
import Repalli2 from "../image/Repalli2.png";
import Repalli3 from "../image/Repalli3.png";
// import Vempalli1 from "../image/Vempalli1.jpeg";
import Vempalli2 from "../image/Vempalli2.png";
import Vempalli3 from "../image/Vempalli3.png";
import Vempalli4 from "../image/Vempalli4.png";
// import Vempalli5 from "../image/Vempalli5.png";
import Vinjumur1 from "../image/Vinjumur1.png";
import Vinjumur2 from "../image/Vinjumur2.png";
import Vinjumur3 from "../image/Vinjumur3.png";
import Vinjumur4 from "../image/Vinjumur4.png";
import Vinjumur5 from "../image/Vinjumur5.png";
import HonnaliStore1 from "../image/HonnaliStore1.png";
import HonnaliStore2 from "../image/HonnaliStore2.png";
import HonnaliStore3 from "../image/HonnaliStore3.png";
import HonnaliStore4 from "../image/HonnaliStore4.png";
import HonnaliStore5 from "../image/HonnaliStore5.png";
import HonnaliStore6 from "../image/HonnaliStore6.png";
import HandPost1 from "../image/HandPost1.png";
import HandPost2 from "../image/HandPost2.png";
import HandPost3 from "../image/HandPost3.png";
import Nuziveedu1 from "../image/Nuziveedu1.png";
import Nuziveedu2 from "../image/Nuziveedu2.png";
import Nuziveedu3 from "../image/Nuziveedu3.png";
import Nuziveedu4 from "../image/Nuziveedu4.png";
import Nuziveedu5 from "../image/Nuziveedu5.png";
import Nuziveedu6 from "../image/Nuziveedu6.png";
import Nuziveedu7 from "../image/Nuziveedu7.png";
import Nuziveedu8 from "../image/Nuziveedu8.png";
import Kothakota1 from "../image/Kothakota1.png";
import Kothakota2 from "../image/Kothakota2.png";
import Kothakota3 from "../image/Kothakota3.png";
import Kothakota4 from "../image/Kothakota4.png";
import Kothakota5 from "../image/Kothakota5.png";
import Kustagi1 from "../image/Kustagi1.png";
import Kustagi2 from "../image/Kustagi2.png";
import Kustagi3 from "../image/Kustagi3.png";
import Kustagi4 from "../image/Kustagi4.png";
import Kustagi5 from "../image/Kustagi5.png";
import Kustagi6 from "../image/Kustagi6.png";
import Kustagi7 from "../image/Kustagi7.png";
import Kustagi8 from "../image/Kustagi8.png";
import Davangere1 from "../image/Davangere1.png";
import Davangere2 from "../image/Davangere2.png";
import Davangere3 from "../image/Davangere3.png";
import Davangere4 from "../image/Davangere4.png";
import Davangere5 from "../image/Davangere5.png";
import Davangere6 from "../image/Davangere6.png";
import Davangere7 from "../image/Davangere7.png";
import Davangere8 from "../image/Davangere8.png";
import Davangere9 from "../image/Davangere9.png";
import Davangere10 from "../image/Davangere10.png";
import Palamneru1 from "../image/Palamneru1.png";
import Palamneru2 from "../image/Palamneru2.png";
import Palamneru3 from "../image/Palamneru3.png";
import Palamneru4 from "../image/Palamneru4.png";
import Palamneru5 from "../image/Palamneru5.png";
import Palamneru6 from "../image/Palamneru6.png";
import Kalkiri1 from "../image/Kalkiri1.png";
import Kalkiri2 from "../image/Kalkiri2.png";
import Kalkiri3 from "../image/Kalkiri3.png";
import Vijayawada1 from "../image/Vijayawada1.png";
import Vijayawada2 from "../image/Vijayawada2.png";
import Vijayawada3 from "../image/Vijayawada3.png";
import Vijayawada4 from "../image/Vijayawada4.png";
import Vijayawada5 from "../image/Vijayawada5.png";
import Galiveedu1 from "../image/Galiveedu1.png"
import Galiveedu2 from "../image/Galiveedu2.png"
import Galiveedu3 from "../image/Galiveedu3.png"
import Galiveedu4 from "../image/Galiveedu4.png"
import Galiveedu5 from "../image/Galiveedu5.png"
import Gorantala1 from "../image/Gorantala1.png"
import Gorantala2 from "../image/Gorantala2.png"
import Gorantala3 from "../image/Gorantala3.png"
import Gorantala4 from "../image/Gorantala4.png"
import KrPuram1 from "../image/krpuram1.png"
import KrPuram2 from "../image/krpuram2.png"
import KrPuram3 from "../image/krpuram3.png"
import KrPuram4 from "../image/krpuram4.png"
import KrPuram5 from "../image/krpuram5.png"




export const store = [
  // Rannebennur
  {
    id: 1,
    location: "Rannebennur",
    address: "Beside Canara Bank ,Railway Station Road , Ranebennur -581115",
    PinCode: "581115",
    map: "",
    category:"Karnataka",
    number1: 9364099708,
    number2: "",
    image: [
      {
        id: 1,
        url: Ranebennur1,
      },
      {
        id: 2,
        url: Ranebennur2,
      },
      {
        id: 3,
        url: Ranebennur3,
      },
      {
        id: 4,
        url: Ranebennur4,
      },
      {
        id: 5,
        url: Ranebennur5,
      },
      {
        id: 6,
        url: Ranebennur6,
      },
      {
        id: 7,
        url: Ranebennur7,
      },
      {
        id: 8,
        url: Ranebennur8,
      },
      {
        id: 9,
        url: Ranebennur9,
      },
    ],
  },
  // Honnali
  {
    id: 2,
    location: "Honnali",
    address:
      "Pattana Shetty Complex, T B Circle, Honnali, Davanagere, Karnataka, 577217",
    map: "https://maps.app.goo.gl/Zhnjd88hqRvKyfqn6",
    category:"Karnataka",
    number1: 9364099710,
    number2: "",
    image: [
      {
        id: 1,
        url: HonnaliStore6,
      },
      {
        id: 2,
        url: HonnaliStore1,
      },
      {
        id: 3,
        url: HonnaliStore2,
      },
      {
        id: 4,
        url: HonnaliStore3,
      },
      {
        id: 5,
        url: HonnaliStore4,
      },
      {
        id: 6,
        url: HonnaliStore5,
      },
    ],
  },
  // Kustagi
  {
    id: 3,
    location: "Kustagi",
    address: "Buiding no 01 10, Flat no 7688 to 7697, Sri Sai Krishna Residency, Indhira Colony, OPP KSRTC Bus Stop, Kushtagi, Koppal, Karnataka, 583277",
    map: "https://maps.app.goo.gl/mVPja8wK4wFtm8TT6",
    category:"Karnataka",
    number1: 9364099709,
    number2: "",
    image: [
      {
        id: 1,
        url: Kustagi1,
      },
      {
        id: 2,
        url: Kustagi2,
      },
      {
        id: 3,
        url: Kustagi3,
      },
      {
        id: 4,
        url: Kustagi4,
      },
      {
        id: 5,
        url: Kustagi5,
      },
      {
        id: 6,
        url: Kustagi6,
      },
      {
        id: 7,
        url: Kustagi7,
      },
      {
        id: 8,
        url: Kustagi8,
      },
    ],
  },
  // Davanagere
  {
    id: 4,
    location: "Davanagere",
    address: "Door No.#294/1 to 5C, Ashoka Road, Below Bheema Lodge, Davanagere, Davanagere, Karnataka, 577002",
    map: "https://maps.app.goo.gl/Bq2Jr7nRXD2E8KwCA",
    category:"Karnataka",
    number1: 9364099711,
    number2: "",
    image: [
      {
        id: 1,
        url: Davangere1,
      },
      {
        id: 2,
        url: Davangere2,
      },
      {
        id: 3,
        url: Davangere3,
      },
      {
        id: 4,
        url: Davangere4,
      },
      {
        id: 5,
        url: Davangere5,
      },
      {
        id: 6,
        url: Davangere6,
      },
      {
        id: 7,
        url: Davangere7,
      },
      {
        id: 8,
        url: Davangere8,
      },
      {
        id: 9,
        url: Davangere9,
      },
      {
        id: 10,
        url: Davangere10,
      },
    ],
  },
  // Hand Post
  {
    id: 5,
    location: "Hand post",
    address:
      "#317/A Near federal Bank , M m Road R P Circle Handpost HD Kote Road Mysore,571125 ",
    map: "https://maps.app.goo.gl/d2Fesvwzvo7f3q3WA",
    category:"Karnataka",
    number1: 9364099693,
    number2: "",
    image: [
      {
        id: 1,
        url: HandPost2,
      },
      {
        id: 2,
        url: HandPost1,
      },
      {
        id: 3,
        url: HandPost3,
      },
    ],
  },
  // Nuziveedu
  {
    id: 6,
    location: "Nuziveedu",
    address:
      "Ground Floor, Door No.7-1/2, Hanuman Junction Road, Opposite State Bank Of India, Nuzvid, Eluru, Andhra Pradesh, 521201",
    map: "https://maps.app.goo.gl/ji6TKxX3ZVqbp6AS9",
    category: "Andhra" ,
    number1: 9364099679,
    number2:"",
    image: [
      {
        id: 1,
        url: Nuziveedu1,
      },
      {
        id: 2,
        url: Nuziveedu2,
      },
      {
        id: 3,
        url: Nuziveedu3,
      },
      {
        id: 4,
        url: Nuziveedu4,
      },
      {
        id: 5,
        url: Nuziveedu5,
      },
      {
        id: 6,
        url: Nuziveedu6,
      },
      {
        id: 7,
        url: Nuziveedu7,
      },
      {
        id: 8,
        url: Nuziveedu8,
      },
    ],
  },
  // Repalle
  {
    id: 7,
    location: "Repalle",
    address:
      "Ground Floor, Door No.10-14-123, G K R Complex, Municipal Office Road, Railpet, Opposite Sekhar Book Stall, Repalle, Bapatla, Andhra Pradesh, 522265.",
    map: "https://maps.app.goo.gl/4fCCC6WF85uLJcETA",
    category: "Andhra" ,
    number1: 9364099680,
    number2: "",
    image: [
      {
        id: 1,
        url: Repalli1,
      },
      {
        id: 2,
        url: Repalli2,
      },
      {
        id: 3,
        url: Repalli3,
      },
    ],
  },
  // Gorantala
  {
    id: 8,
    location: "Gorantala",
    address: "D No 3/498, Gorantla, Opp Panchaychi Building, Market Street, Gorantla, Sri Sathyasai, Andhra Pradesh, 515231",
    map: "https://maps.app.goo.gl/yRGg8pP3ZnhNThSS7",
    category: "Andhra" ,
    number1: 9364099705,
    number2: "",
    image: [
      {
        id: 1,
        url: Gorantala1,
      },
      {
        id: 2,
        url: Gorantala2,
      },
      {
        id: 3,
        url: Gorantala3,
      },
      {
        id: 4,
        url: Gorantala4,
      },
    ],
  },
  // kalyandurgam
  {
    id: 9,
    location: "kalyandurgam",
    address:"No.8-90, VTS Complex, 8th Ward, Hindupur Road, Opposite Reliance Trends, Kalyandurg Rural, Ananthapuramu, Andhra Pradesh, 515761",
    map: "https://maps.app.goo.gl/NEpWBpgMFbWtJEc36",
    category: "Andhra" ,
    number1: 9364099703,
    number2: "",
  },
  // Ananthpur
  {
    id: 10,
    location: "Ananthpur",
    address:
      "D.No. 15-68 Gayathri Complex Opp Vasan Eye Care Raju Road ANANTAPUR-515001",
    map: "https://maps.app.goo.gl/HcUC23Js6Xc8xmLP9",
    category: "Andhra" ,
    number1: 9364099701,
    number2:"",
    image: [
      {
        id: 1,
        url: Ananthpur5,
      },
      {
        id: 2,
        url: Ananthpur1,
      },
      {
        id: 3,
        url: Ananthpur7,
      },
      {
        id: 4,
        url: Ananthpur8,
      },
    ],
  },
  // Kadiri
  {
    id: 11,
    location: "Kadiri",
    address:
      "Ground Floor, No. 1/332-A, Bypass Road, Opp Radika Theater, Kadiri, Sri Sathyasai, Andhra Pradesh, 515591",
    map: "https://maps.app.goo.gl/nkpGZrHu3tGZA9dE8",
    category: "Andhra" ,
    number1: 9364099707,
    number2: "",
    image: [
      {
        id: 1,
        url: Kadiri1,
      },
      {
        id: 2,
        url: Kadiri2,
      },
      {
        id: 3,
        url: Kadiri3,
      },
      {
        id: 4,
        url: Kadiri4,
      },
      {
        id: 5,
        url: Kadiri5,
      },
      {
        id: 6,
        url: Kadiri6,
      },
      {
        id: 7,
        url: Kadiri7,
      },
      {
        id: 8,
        url: Kadiri8,
      },
      {
        id: 9,
        url: Kadiri9,
      },
    ],
  },
  // Palamneru
  {
    id: 12,
    location: "Palamneru",
    address:
      "#12-42/1,Badri Complex, Javilli Street, Near Manjunatha Theater, PALAMANER-517408",
    map: "https://maps.app.goo.gl/mouMt97WdNGEDB6y9",
    category: "Andhra" ,
    number1: 9364099704,
    number2: "",
    image: [
      {
        id: 1,
        url: Palamneru1,
      },
      {
        id: 2,
        url: Palamneru2,
      },
      {
        id: 3,
        url: Palamneru3,
      },
      {
        id: 4,
        url: Palamneru4,
      },
      {
        id: 5,
        url: Palamneru5,
      },
      {
        id: 6,
        url: Palamneru6,
      },
    ],
  },
  // Galiveedu
  {
    id: 13,
    location: "Galiveedu",
    address: "D.No.5/478, Near Busstand Rayachoti Road, GALIVEEDU-516267",
    map: "https://maps.app.goo.gl/ur7cVwVe2GNvXbnB7",
    category: "Andhra" ,
    number1: 9364099706,
    number2: "",
    image: [
      {
        id: 1,
        url: Galiveedu1,
      },
      {
        id: 2,
        url: Galiveedu2,
      },
      {
        id: 3,
        url: Galiveedu3,
      },
      {
        id: 4,
        url: Galiveedu4,
      },
      {
        id: 5,
        url: Galiveedu5,
      },
    ],
  },
  // Vijayawada
  {
    id: 14,
    location: "Vijayawada",
    address:
      "Ground Floor, Door No.29-37-163, RCC Building, Eluru Road, Opposite Sonovision, Vijayawada, NTR, Andhra Pradesh, 520002",
    map: "https://maps.app.goo.gl/n1KknTAqw9uBQdRc9",
    category: "Andhra" ,
    number1: 9364099696,
    number2:"",
    image: [
      {
        id: 1,
        url: Vijayawada1,
      },
      {
        id: 2,
        url: Vijayawada2,
      },
      {
        id: 3,
        url: Vijayawada3,
      },
      {
        id: 4,
        url: Vijayawada4,
      },
      {
        id: 5,
        url: Vijayawada5,
      },
    ],
  },
  // Periyapatna
  {
    id: 15,
    location: "Periyapatna",
    address: "Ground Floor, No. #1524/1, Yajaman Complex, Santhepete Road, B-M Main Road, Near laxmi clinic, Periyapatna, Mysuru, Karnataka, 571107",
    map: "https://maps.app.goo.gl/e4rH1joVmrFMHS7U9",
    category:"Karnataka",
    number1: 9364099592,
    number2:"",
    image: [
      {
        id: 1,
        url: Periyapatna2,
      },
      {
        id: 2,
        url: Periyapatna1,
      },
      {
        id: 3,
        url: Periyapatna3,
      },
      {
        id: 4,
        url: Periyapatna4,
      },
      {
        id: 5,
        url: Periyapatna5,
      },
    ],
  },
  // Vempalli
  {
    id: 16,
    location: "Vempalli",
    address: "Ground floor,No 6/185,Patha Peta,Opp BSNL Office,Vempalli-516329",
    map: "https://maps.app.goo.gl/1nhQ131yEdCebjV19",
    category: "Andhra" ,
    number1: 9364099695,
    number2: "",
    image: [
      {
        id: 1,
        url: Vempalli4,
      },
      {
        id: 2,
        url: Vempalli2,
      },
      {
        id: 3,
        url: Vempalli3,
      },
    ],
  },
  // Vinjamur
  {
    id: 17,
    location: "Vinjamur",
    address: "#5/78, Beside Sangeetha Mobiles, Vinjamur, Sri Potti Sriramulu Nellore, Andhra Pradesh, 524228",
    map: "https://maps.app.goo.gl/8hGUhj1kb77jj3E99",
    category: "Andhra" ,
    number1: 9364099694,
    number2: "",
    image: [
      {
        id: 1,
        url: Vinjumur1,
      },
      {
        id: 2,
        url: Vinjumur2,
      },
      {
        id: 3,
        url: Vinjumur3,
      },
      {
        id: 4,
        url: Vinjumur4,
      },
      {
        id: 5,
        url: Vinjumur5,
      },
    ],
  },
  // Rayachoti
  {
    id: 18,
    location: "Rayachoti",
    address:
      "Ground Floor, No 61-130, Chittoor Road, Beside Bombay Lodge, Rayachoti, Annamayya, Andhra Pradesh, 516269",
    map: "https://maps.app.goo.gl/tMW9PSut5kBkKVEN6",
    category: "Andhra" ,
    number1: 9364099678,
    number2:"",
    image: [
      {
        id: 1,
        url: Rayachuti1,
      },
      {
        id: 2,
        url: Rayachuti2,
      },
      {
        id: 3,
        url: Rayachuti3,
      },
      {
        id: 4,
        url: Rayachuti4,
      },
      {
        id: 5,
        url: Rayachuti5,
      },
      {
        id: 6,
        url: Rayachuti6,
      },
      {
        id: 7,
        url: Rayachuti7,
      },

      {
        id: 8,
        url: Rayachuti8,
      },
    ],
  },
  // Kalkiri
  {
    id: 19,
    location: "Kalkiri",
    address: "1st Floor,No 6-649,T B Road,Near RTC Busstand,Kalkiri-517234",
    map: "https://maps.app.goo.gl/TgXhGZ7eSYcRhujZ7",
    category: "Andhra" ,
    number1: 9364099677,
    number2: "",
    image: [
      {
        id: 1,
        url: Kalkiri1,
      },
      {
        id: 2,
        url: Kalkiri2,
      },
      {
        id: 3,
        url: Kalkiri3,
      },
    ],
  },
  // B.Kothakota
  {
    id: 20,
    location: "B.Kothakota",
    address:
      "1st Floor, No 10-169, Banglore Road, Beside Apollo Pharmacy, B Kothakota, Annamayya, Andhra Pradesh, 517370",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category: "Andhra" ,
    number1: 9364099676,
    number2: "",
    image: [
      {
        id: 1,
        url: Kothakota2,
      },
      {
        id: 2,
        url: Kothakota1,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // Badvel
  {
    id: 21,
    location: "Badvel",
    address:
      "#8-2-258, Mydukuru  Road, Kadapa Dist,Kadapa, Badvel-516227",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category: "Andhra" ,
    number1: 9364099681,
    number2: "",
    image: [
      {
        id: 1,
        url: Kothakota1,
      },
      {
        id: 2,
        url: Kothakota2,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // Harihara
  {
    id: 22,
    location: "Harihara",
    address:
      "Ground Floor, Door no 570/A, RCC Building, P B Road , Harihara, Opp Government Bus Stop, Sarathi Industrial Area, Davanagere, Karnataka, 577601",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category:"Karnataka",
    number1: 9364099684,
    number2:"",
    image: [
      {
        id: 1,
        url: Kothakota2,
      },
      {
        id: 2,
        url: Kothakota1,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // KR Puram, Bangalore
  {
    id: 23,
    location: "KR Puram, Bangalore",
    address:"Building at no 3, Roj Garden 4th Devasandra main road, KR Puram Bangalore North 560036",
    map: "https://maps.app.goo.gl/Aa2tZox7UAkCjZa26",
    category:"Bangalore",
    number1: 9364099692,
    number2:"",
    image: [
      {
        id: 1,
        url: KrPuram1,
      },
      {
        id: 2,
        url: KrPuram2,
      },
      {
        id: 3,
        url: KrPuram3,
      },
      {
        id: 4,
        url: KrPuram4,
      },
      {
        id: 5,
        url: KrPuram5,
      },
    ],
  },

  {
    id: 24,
    location: "Hosapete",
    address:"No.73C, 1st Division, Gandhi Circle, Murane Angandi Circle, Hosapete, Vijayanagara, Karnataka, 583201",
    map: "",
    category:"Karnataka",
    number1: "",
    number2:"",
    image: [],
  },
  {
    id: 25,
    location: "Rabenennur",
    address:"1,2, CTS No.1855, Railway Station Road, Near Canara Bank, Ranebennur, Haveri, Karnataka, 581115",
    map: "",
    category:"Karnataka",
    number1: "",
    number2:"",
    image: [],
  },
  {
    id: 26,
    location: "Ananthapur",
    address:"Ground Floor, D.No 15-68-1, Gayathri Complex, Raju Road, Opposite Vasan Eye Care Hospital, Ananthapuramu, Ananthapuramu, Andhra Pradesh, 515001",
    map: "",
    category:"Andhra",
    number1: 9364099701,
    number2:"",
    image: [],
  },

  {
    id: 27,
    location: "Kalikiri",
    address:"1st Floor, No 6-469, T B Road, Near RTC Busstand, Kalikiri, Annamayya, Andhra Pradesh, 517234",
    map: "",
    category:"Andhra",
    number1: 9364099677,
    number2:"",
    image: [],
  },
  {
    id: 28,
    location: "Madanapalli",
    address:"Do No.14-1-4-4, CTM Road, Near Old Busstand, Madanapalli, Annamayya Dist, AP - 517325",
    map: "",
    category:"Andhra",
    number1: 9364099682,
    number2:"",
    image: [],
  },
  {
    id: 29,
    location: "Rayadurgam",
    address:"D-No=9-2-230, Laxmi Bazar Anantapur Road, Rayadurgam, Anantapur District, AP -  515865",
    map: "",
    category:"Andhra",
    number1: 9364099691,
    number2:"",
    image: [],
  },
  {
    id: 30,
    location: "Vayalpadu",
    address:"D No. 1-606, Opp RTC Busstand, Vayalpadu, Annamayya Dist, Andhra Pradesh - 517299",
    map: "",
    category:"Andhra",
    number1: 9364099687,
    number2:"",
    image: [],
  },
  {
    id: 31,
    location: "Vempalle",
    address:"Ground Floor, No 6/185, Patha Peta, Opp BSNL Office, Vempalle, YSR, Andhra Pradesh, 516329",
    map: "",
    category:"Andhra",
    number1: 9364099695,
    number2:"",
    image: [],
  },
  {
    id: 30,
    location: "Vayalpadu",
    address:"D No. 1-606, Opp RTC Busstand, Vayalpadu, Annamayya Dist, AP - 517299",
    map: "",
    category:"Andhra",
    number1: 9364099687,
    number2:"",
    image: [],
  },
];
